import * as React from 'react'
import styled from 'styled-components'

import theme from '~/truck/theme'
import { images, riqraInfo } from '~/utils/constants'

import { CenterContainer } from '../elements'

const Container = styled.div`
  align-items: center;
  background: ${props => props.theme.colors.primary};
  display: flex;
  height: ${props => props.theme.spacing.cozy * 6}px;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
`

const Header = () => {
  return (
    <Container>
      <CenterContainer>
        <a href={riqraInfo.website}>
          <img src={images.RIQRA_LOGOTYPE_WHITE} height={theme.spacing.cozy * 4} alt="Riqra Logo" />
        </a>
      </CenterContainer>
    </Container>
  )
}

export default Header
