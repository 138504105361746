import React from 'react'

import Icon from '~/truck/icon'
import Label from '~/truck/label'
import { emailUrl, phoneUrl } from '~/utils'

import { Container, Navigation, StyledMetricLink } from './elements'

const ContactItem = props => {
  const { type, displayValue, href, textStyle, ...rest } = props

  if (!href && !displayValue) {
    return null
  }

  return (
    <StyledMetricLink href={href} rel="nofollow" type={type} {...rest}>
      {type && <Icon type={type} color="black" />}
      {displayValue && (
        <Label as="span" $textStyle={textStyle}>
          {displayValue}
        </Label>
      )}
    </StyledMetricLink>
  )
}

const Contact = props => {
  const { vendor, asHeader, className, style } = props

  const { facebook, instagram, youtube } = vendor

  const hasSocialNetwork = facebook || instagram || youtube

  return (
    <Container $asHeader={asHeader} className={className} style={style}>
      {!asHeader && (
        <Label as="h5" $color="black" $textStyle="h5Semibold">
          Contacto
        </Label>
      )}
      <Navigation $asHeader={asHeader}>
        <ContactItem
          type={asHeader && 'mail'}
          displayValue={vendor.contactEmail}
          href={emailUrl(vendor.contactEmail)}
        />
        <ContactItem
          type="telephone"
          displayValue={vendor.phone}
          href={phoneUrl(vendor.phone)}
          textStyle={!asHeader && 'h4Semibold'}
        />
        <ContactItem
          type="whatsapp"
          displayValue={vendor.whatsapp}
          href={vendor.whatsappUrl}
          textStyle={!asHeader && 'h4Semibold'}
          rel="noopener noreferrer nofollow"
          target="_blank"
        />
      </Navigation>
      {hasSocialNetwork && (
        <>
          <Label as="span">Síguenos en:</Label>
          <Navigation $asHeader $onlyIcons>
            <StyledMetricLink href={facebook} type="facebook" external />
            <StyledMetricLink href={instagram} type="instagram" external />
            <StyledMetricLink href={youtube} type="youtube" external />
          </Navigation>
        </>
      )}
    </Container>
  )
}

export default React.memo(Contact)
