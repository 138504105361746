import React, { useEffect, useRef, useState } from 'react'

import { useCartTotalQuantity } from '~/hooks'
import useCartAnimation from '~/hooks/use-cart-animation'

import { OpenCartButtonFloatingView } from './OpenCartButtonFloating.view'

const getOffset = el => {
  const bounds = el.getBoundingClientRect()

  return {
    top: bounds.top + document.body.scrollTop,
    bottom: bounds.top + el.clientHeight,
  }
}

export const OpenCartButtonFloating = () => {
  const buttonRef = useRef(null)

  const footerRef = useRef(null)

  const [alignRight, setAlignRight] = useState(false)

  const lineItemsTotalQuantity = useCartTotalQuantity()

  useEffect(() => {
    footerRef.current = document.body.querySelector('footer')

    const watchOverlappingWithFooter = () => {
      if (!buttonRef.current || !footerRef.current) {
        return
      }

      const buttonOffset = getOffset(buttonRef.current)

      const footerOffset = getOffset(footerRef.current)

      setAlignRight(buttonOffset.bottom >= footerOffset.top)
    }

    window.addEventListener('scroll', watchOverlappingWithFooter)

    return () => {
      window.removeEventListener('scroll', watchOverlappingWithFooter)
    }
  }, [buttonRef])

  const { triggerAnimation, setTriggerAnimation, setAnimationPlaying } = useCartAnimation(lineItemsTotalQuantity)

  const handleOnAnimationStart = () => {
    setAnimationPlaying(true)
  }

  const handleOnAnimationEnd = () => {
    setTriggerAnimation(false)
    setAnimationPlaying(false)
  }

  return (
    <OpenCartButtonFloatingView
      ref={buttonRef}
      lineItemsTotalQuantity={lineItemsTotalQuantity}
      triggerAnimation={triggerAnimation}
      onAnimationStart={handleOnAnimationStart}
      onAnimationEnd={handleOnAnimationEnd}
      alignRight={alignRight}
      clientWidth={buttonRef.current?.clientWidth}
    />
  )
}
