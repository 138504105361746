import React from 'react'
import { Flag } from 'toggled'

import { RouterLink } from '~/components'
import type { IUser } from '~/types/users'
import { logout } from '~/utils'
import { flags } from '~/utils/constants'

import CollapsibleMenuItem, { CollapsibleMenuAnchor } from '../collapsible-menu-item'
import MenuItem from '../menu-item'

export interface MenuProps {
  user: IUser
  className?: string
  style?: React.CSSProperties
}

export default function Menu(props: MenuProps) {
  const { user, style, className } = props

  return (
    <nav className={className} style={style}>
      <RouterLink href="/mi-cuenta" passHref>
        <MenuItem iconType="profile">Mi cuenta</MenuItem>
      </RouterLink>
      <RouterLink href="/mi-cuenta/cambiar-contrasena" passHref>
        <MenuItem iconType="lock">Cambiar contraseña</MenuItem>
      </RouterLink>
      {user?.lineOfCredit ? (
        <RouterLink href="/mi-cuenta/linea-de-credito" passHref>
          <MenuItem iconType="card">Línea de crédito</MenuItem>
        </RouterLink>
      ) : null}
      <RouterLink href="/mi-cuenta/pedidos" passHref>
        <MenuItem iconType="order">Mis pedidos</MenuItem>
      </RouterLink>
      <Flag flagQuery={flags.PRICE_QUOTATION_NAVIGATION}>
        {user.isLoggedIn && user.quotationsPermission && (
          <RouterLink href="/mi-cuenta/cotizaciones" passHref>
            <MenuItem iconType="document">Mis cotizaciones</MenuItem>
          </RouterLink>
        )}
      </Flag>
      <Flag flagQuery={flags.ALLOW_REPURCHASE}>
        <CollapsibleMenuItem title="Comprar de nuevo" iconType="update">
          <RouterLink href="/mi-cuenta/mas-comprados" passHref>
            <CollapsibleMenuAnchor>Productos más comprados</CollapsibleMenuAnchor>
          </RouterLink>
          <RouterLink href="/mi-cuenta/ultimo-pedido" passHref>
            <CollapsibleMenuAnchor>Mi último pedido</CollapsibleMenuAnchor>
          </RouterLink>
        </CollapsibleMenuItem>
      </Flag>
      <MenuItem iconType="logout" onClick={logout}>
        Cerrar sesión
      </MenuItem>
    </nav>
  )
}
