import React from 'react'
import { useFlag } from 'toggled'

import { Loader, MainHeader, Media } from '~/components'
import { OpenCartButtonFloating } from '~/modules/shopping-cart/containers/open-cart-button-floating/OpenCartButtonFloating'
import { flagQueries } from '~/utils/constants'

import { StyledCart, StyledContent, StyledFooter } from './elements'

interface MainLayoutProps {
  loading?: boolean
  children: React.ReactNode
}

function MainLayout({ loading, children }: MainLayoutProps) {
  const isFullWidth = useFlag(flagQueries.HAS_FULL_WIDTH)

  return (
    <>
      <MainHeader />
      <Media desktop={!isFullWidth && <StyledCart />} mobile={<OpenCartButtonFloating />} />
      <StyledContent $isFullWidth={isFullWidth}>{loading ? <Loader /> : children}</StyledContent>
      <StyledFooter $isFullWidth={isFullWidth} />
    </>
  )
}

export default MainLayout
