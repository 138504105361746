import { up } from 'styled-breakpoints'
import styled from 'styled-components'

import { Cart } from '~/modules/shopping-cart/containers/cart/Cart'
import theme from '~/truck/theme'
import { dimensions } from '~/variables'

import { LayoutContent } from '../elements'

import Footer from './footer'

interface StyledContentProps {
  $isFullWidth: boolean
}

export const StyledContent = styled(LayoutContent)<StyledContentProps>`
  align-items: flex-start;

  ${up('lg')} {
    margin: 0;
    width: ${props => (props.$isFullWidth ? '100%' : `calc(100% - ${dimensions.cartWidth}px)`)};
  }
`

export const StyledCart = styled(Cart)`
  bottom: 0;
  position: fixed;
  right: 0;
  width: ${dimensions.cartWidth}px;
`

interface StyledFooterProps {
  $isFullWidth: boolean
}

export const StyledFooter = styled(Footer)<StyledFooterProps>`
  margin-top: ${theme.spacing.cozy * 8}px;

  ${up('lg')} {
    width: ${props => (props.$isFullWidth ? '100%' : `calc(100% - ${dimensions.cartWidth}px)`)};
  }
`
