import styled, { css, keyframes } from 'styled-components'

import theme from '~/truck/theme'
import { stack } from '~/variables'

interface ContainerProps {
  alignRight: boolean
  clientWidth: number
  triggerAnimation: boolean
}

const getCalculatedLeft = (props: ThemeProps<ContainerProps>) => {
  const { alignRight, clientWidth } = props

  if (!alignRight) {
    return ''
  }

  return css`
    left: calc(100% - ${theme.spacing.comfortable + clientWidth}px);
  `
}

const popInAnimation = keyframes`
  66% {transform: scale(1.1);}
  90% {transform: scale(0.95);}
`

export const Container = styled.div<ContainerProps>`
  bottom: ${theme.spacing.comfortable}px;
  left: ${theme.spacing.comfortable}px;
  position: fixed;
  transition: left 0.25s ease-in-out;
  width: unset;
  z-index: ${stack.cartButton};

  ${getCalculatedLeft}

  > button {
    padding: 0 ${theme.spacing.comfortable}px;
  }

  ${({ triggerAnimation }) =>
    triggerAnimation &&
    css`
      animation: ${popInAnimation} 0.4s ease-in-out;
    `}}
`
