import React from 'react'
import clsx from 'clsx'

import Icon from '~/truck/icon'

export interface MenuItemProps {
  iconType: string
  children: string
  href?: string
  selected?: boolean
  onClick?: () => void
}

export default React.forwardRef<HTMLAnchorElement, MenuItemProps>(function MenuItem(props, ref) {
  const { iconType, children, selected, onClick, href } = props

  return (
    <a
      className={clsx('flex h-12 items-center gap-2', selected && 'font-semibold text-brand-primary')}
      onClick={onClick}
      href={href}
      ref={ref}
    >
      <Icon type={iconType} color="currentColor" />
      <span>{children}</span>
    </a>
  )
})
