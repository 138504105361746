import styled, { css } from 'styled-components'

import theme from '~/truck/theme'

import MetricLink from '../metric-link'

interface NavItemProps {
  $asHeader?: boolean
  $onlyIcons?: boolean
}

const getNavItemMarginStyle = (props: NavItemProps) => {
  const { $asHeader, $onlyIcons } = props

  if ($onlyIcons) {
    return css`
      margin-right: ${theme.spacing.cozy}px;
    `
  }

  if ($asHeader) {
    return css`
      margin-right: ${theme.spacing.comfortable}px;
    `
  }

  return css`
    margin-bottom: ${theme.spacing.cozy}px;
  `
}

const getFlexDirection = (props: NavItemProps) => {
  const { $asHeader } = props

  if ($asHeader) {
    return 'row'
  }

  return 'column'
}

export const Navigation = styled.nav<NavItemProps>`
  display: flex;
  flex-direction: ${getFlexDirection};

  > a:not(:last-child) {
    ${getNavItemMarginStyle};
  }
`

export const StyledMetricLink = styled(MetricLink)`
  align-items: center;
  display: flex;

  > svg:not(:only-child) {
    margin-right: ${theme.spacing.cozy}px;
  }
`

const getColor = (props: NavItemProps) => {
  const { $asHeader } = props

  if ($asHeader) {
    return theme.colors.white
  }

  return theme.colors.black
}

const getFollowUsStyle = (props: NavItemProps) => {
  const { $asHeader } = props

  if ($asHeader) {
    return css`
      align-self: center;
      margin-left: auto;
      margin-right: ${theme.spacing.cozy}px;
    `
  }

  return css`
    margin-bottom: ${theme.spacing.cozy}px;
    margin-top: ${theme.spacing.comfortable}px;
  `
}

export const Container = styled.div`
  display: flex;
  flex-direction: ${getFlexDirection};

  > h5 {
    margin-bottom: ${theme.spacing.comfortable}px;
  }

  > span {
    ${getFollowUsStyle}
  }

  span {
    color: ${getColor};
  }

  svg {
    fill: ${getColor};
  }
`
