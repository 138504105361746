import React, { forwardRef } from 'react'
import Link from 'next/link'

import Icon from '~/truck/icon'
import NewButton from '~/truck/new-button/NewButton'

import { Container } from './OpenCartButtonFloating.style'

interface OpenCartButtonFloatingViewProps {
  lineItemsTotalQuantity: number
  triggerAnimation?: boolean
  onAnimationEnd: () => void
  onAnimationStart: () => void
  alignRight?: boolean
  clientWidth?: number
}

export const OpenCartButtonFloatingView = forwardRef<HTMLDivElement, OpenCartButtonFloatingViewProps>((props, ref) => {
  const { lineItemsTotalQuantity, triggerAnimation, onAnimationEnd, onAnimationStart, alignRight, clientWidth } = props

  return (
    <Link href="/carrito">
      <Container
        ref={ref}
        alignRight={alignRight}
        clientWidth={clientWidth}
        triggerAnimation={triggerAnimation}
        onAnimationStart={onAnimationStart}
        onAnimationEnd={onAnimationEnd}
      >
        <NewButton>
          <Icon type="cart" color="currentColor" /> {lineItemsTotalQuantity}
        </NewButton>
      </Container>
    </Link>
  )
})
