import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import { isFunction } from 'lodash'
import { useFlag } from 'toggled'

import { Loader } from '~/components'
import Contact from '~/components/contact'
import MainHeader from '~/components/main-header'
import Media from '~/components/media'
import { userQuery } from '~/gql/queries'
import useVendor from '~/hooks/use-vendor'
import { flags } from '~/utils/constants'

import { Container, Content, Sidebar, StyledMenu } from './elements'
import Menu from './menu'

const getChildren = props => {
  const { children, ...restProps } = props

  if (!isFunction(children)) {
    return children
  }

  return children(restProps)
}

const AccountLayout = props => {
  const { children, noPadding = false, fullHeight = false, includesCart = false } = props

  const vendor = useVendor()

  const hasLineOfCredit = useFlag(flags.LINES_OF_CREDIT)

  // FIXME: use default `fetchPolicy` no matter anything
  const userResponse = useQuery(userQuery, {
    fetchPolicy: includesCart ? 'network-only' : 'cache-first',
    variables: {
      includesCart,
      includesLineOfCredit: hasLineOfCredit,
    },
  })

  return (
    <>
      <MainHeader />
      <Container>
        {userResponse.loading ? (
          <Loader />
        ) : (
          <>
            <Media
              mobile={<StyledMenu user={userResponse.data.user} />}
              desktop={
                <Sidebar>
                  <Menu user={userResponse.data.user} />
                  <Contact vendor={vendor} />
                </Sidebar>
              }
            />
            <Content noPadding={noPadding} fullHeight={fullHeight}>
              {getChildren({
                children,
                user: userResponse.data.user,
              })}
            </Content>
          </>
        )}
      </Container>
    </>
  )
}

export default AccountLayout
