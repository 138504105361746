import * as React from 'react'
import { useQuery } from '@apollo/react-hooks'
import clsx from 'clsx'
import { filter, isEmpty, map, uniqBy } from 'lodash'
import { useFlag } from 'toggled'

import { analytics } from '~/analytics'
import { CustomerComplaints } from '~/components'
import { paymentMethodsImageQuery } from '~/gql/queries'
import useVendor from '~/hooks/use-vendor'
import Icon from '~/truck/icon'
import Image from '~/truck/image'
import theme from '~/truck/theme'
import cloudinary from '~/utils/cloudinary'
import { flags } from '~/utils/constants'
import { emailUrl, phoneUrl } from '~/utils/urls'

function Contact() {
  const vendor = useVendor()

  return (
    <div className="flex flex-col gap-2">
      <h5 className="text-xl font-semibold">Contacto</h5>
      {vendor.fiscalName && <p className="text-sm">{vendor.fiscalName}</p>}
      {vendor.fiscalAddress && <p className="text-sm">{vendor.fiscalAddress}</p>}
      {vendor.contactEmail && (
        <a
          className="text-sm"
          href={emailUrl(vendor.contactEmail)}
          onClick={() => analytics.contact('mail')}
          rel="nofollow"
        >
          {vendor.contactEmail}
        </a>
      )}
      {vendor.phone && (
        <a
          className="text-xl font-semibold"
          href={phoneUrl(vendor.phone)}
          onClick={() => analytics.contact('telephone')}
          rel="nofollow"
        >
          {vendor.phone}
        </a>
      )}
      <div className="flex gap-2 leading-none">
        {vendor.facebook && (
          <a
            href={vendor.facebook}
            target="_blank"
            rel="noopener noreferrer nofollow"
            onClick={() => analytics.socialMedia('facebook')}
          >
            <Icon type="facebook" interactive={false} color="currentColor" />
          </a>
        )}
        {vendor.whatsappUrl && (
          <a
            href={vendor.whatsappUrl}
            target="_blank"
            rel="noopener noreferrer nofollow"
            onClick={() => analytics.contact('whatsapp')}
          >
            <Icon type="whatsapp" interactive={false} color="currentColor" />
          </a>
        )}
        {vendor.instagram && (
          <a
            href={vendor.instagram}
            target="_blank"
            rel="noopener noreferrer nofollow"
            onClick={() => analytics.socialMedia('instagram')}
          >
            <Icon type="instagram" interactive={false} color="currentColor" />
          </a>
        )}
        {vendor.youtube && (
          <a
            href={vendor.youtube}
            target="_blank"
            rel="noopener noreferrer nofollow"
            onClick={() => analytics.socialMedia('youtube')}
          >
            <Icon type="youtube" interactive={false} color="currentColor" />
          </a>
        )}
      </div>
    </div>
  )
}

function About() {
  const vendor = useVendor()

  return (
    <div className="flex flex-col gap-2">
      <h5 className="text-xl font-semibold">Acerca de</h5>
      {map(vendor.settings?.storefrontLinks, link => (
        <a className="text-sm" key={link.label} href={link.url} target="_blank" rel="noopener noreferrer nofollow">
          {link.label}
        </a>
      ))}
    </div>
  )
}

function PaymentMethods() {
  const { data } = useQuery(paymentMethodsImageQuery)

  const hasCustomerComplaints = useFlag(flags.CUSTOMER_COMPLAINTS)

  const paymentMethods = uniqBy(data?.vendor?.paymentMethods, 'image') as any[]

  const dataSource = filter(paymentMethods, paymentMethod => !!paymentMethod.image)

  return (
    <div className="flex flex-col gap-2">
      {!isEmpty(dataSource) && (
        <>
          <h5 className="text-xl font-semibold">Medios de Pago</h5>
          <div className="flex flex-wrap gap-2">
            {map(dataSource, paymentMethod => (
              <Image
                key={paymentMethod.image}
                height="auto"
                width="auto"
                alt="payment cards"
                src={cloudinary.applyTransformations(paymentMethod.image)}
              />
            ))}
          </div>
        </>
      )}
      {hasCustomerComplaints && <CustomerComplaints className="lg:self-left mt-6 self-end" />}
    </div>
  )
}

function PoweredBy() {
  const vendor = useVendor()

  return (
    <div className="flex items-center justify-center gap-2">
      <span>Powered by</span>
      {/* eslint-disable-next-line react/jsx-no-target-blank */}
      <a
        className="flex items-center"
        href={`https://riqra.com/?utm_source=tiendas&utm_medium=footer&utm_campaign=referido_tiendas&utm_term=${vendor.manufacturer.name}`}
        target="_blank"
      >
        <Image src={theme.branding.logotypeWhite} height={theme.spacing.relaxed} />
      </a>
    </div>
  )
}

interface FooterProps {
  className?: string
}

function Footer({ className }: FooterProps) {
  const hasHidePoweredByRiqraFF = useFlag(flags.HIDE_POWERED_BY_RIQRA)

  return (
    <footer
      className={clsx(
        'flex flex-col justify-between gap-12 break-words bg-[#2d333c] p-8 pb-4 text-white lg:min-h-80',
        className,
      )}
    >
      <div className="grid gap-8 lg:grid-cols-3 lg:gap-2">
        <Contact />
        <About />
        <PaymentMethods />
      </div>
      {!hasHidePoweredByRiqraFF && <PoweredBy />}
    </footer>
  )
}

export default Footer
