import * as React from 'react'

import { Header } from './components'

interface StandaloneLayoutProps {
  children: React.ReactNode
}

const StandaloneLayout = (props: StandaloneLayoutProps) => {
  const { children } = props

  return (
    <>
      <Header />
      {children}
    </>
  )
}

export default StandaloneLayout
