import React from 'react'

import Media from '~/components/media'
import useVendor from '~/hooks/use-vendor'
import { cloudinary } from '~/utils'
import { transformations } from '~/utils/constants'

import { Banner, BannerContainer, Container, InnerContainer, Logo } from './elements'

const PrivateAuthLayout = props => {
  const { children } = props

  const vendor = useVendor()

  const loginBg = cloudinary.applyTransformations(vendor.loginBg, transformations.PRIVATE_LOGIN_BG)

  return (
    <Container>
      <Logo vendor={vendor} alternative size="large" />
      <InnerContainer>{children}</InnerContainer>
      <BannerContainer src={loginBg}>
        <Media desktop={<Banner src={loginBg} />} />
      </BannerContainer>
    </Container>
  )
}

export default PrivateAuthLayout
