import { up } from 'styled-breakpoints'
import styled from 'styled-components'

import BaseLogo from '~/components/logo'
import theme from '~/truck/theme'

export const Container = styled.div`
  display: flex;
  position: relative;
  width: 100%;

  ${up('lg')} {
    height: 100vh;
  }
`

export const InnerContainer = styled.section`
  background: ${theme.colors.white};
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.comfortable}px;
  height: inherit;
  padding: 0 ${theme.spacing.comfortable}px;
  padding-top: ${theme.spacing.cozy * 15}px;
  width: 100%;

  ${up('lg')} {
    justify-content: center;
    padding: 0 ${theme.spacing.cozy * 8}px;
    width: ${theme.spacing.cozy * 61}px;
  }
`

export const Logo = styled(BaseLogo)`
  cursor: default;
  height: ${theme.spacing.cozy * 5}px;
  left: 50%;
  object-fit: contain;
  object-position: top;
  position: absolute;
  right: 0;
  text-align: center;
  top: ${theme.spacing.comfortable}px;
  transform: translateX(-50%);
  width: ${theme.spacing.cozy * 20}px;

  ${up('lg')} {
    left: ${theme.spacing.comfortable}px;
    text-align: start;
    transform: unset;
  }
`

export const BannerContainer = styled.div`
  background-image: url(${props => props.src});
  background-position: center;
  background-size: cover;
  display: flex;
  flex: 1;
`

export const Banner = styled.img`
  display: none;

  ${up('lg')} {
    backdrop-filter: blur(${theme.spacing.cozy * 12.5}px);
    background: rgba(255, 255, 255, 0.2);
    display: flex;
    flex: 1;
    object-fit: contain;
  }
`
