import { up } from 'styled-breakpoints'
import styled, { css } from 'styled-components'

import theme from '~/truck/theme'
import { themeGetters } from '~/truck/utils'
import { viewportHeights } from '~/variables'

import { LayoutContent } from '../elements'

import Menu from './menu'

const { spacing, colors } = themeGetters

export const Container = styled(LayoutContent)`
  max-width: ${theme.spacing.cozy * 128}px;

  ${up('lg')} {
    column-gap: ${theme.spacing.comfortable}px;
    flex-direction: row;
    min-height: 0;
  }
`

export const Sidebar = styled.div`
  background-color: ${colors('white')};
  display: flex;
  flex-direction: column;
  min-height: ${viewportHeights.getDefaultLayout};
  padding: 0 ${spacing('comfortable')}px;
  width: ${theme.spacing.cozy * 40}px;

  > :first-child {
    border-bottom: 1px solid ${colors('gray')};
    padding-bottom: ${spacing('comfortable')}px;
  }

  > :last-child {
    padding-top: ${spacing('comfortable')}px;
  }
`

const getPadding = props => {
  const { noPadding } = props

  if (noPadding) {
    return 0
  }

  return spacing('comfortable')
}

const getHeightStyle = props => {
  const { fullHeight } = props

  const height = viewportHeights.getDefaultLayout(props)

  if (!fullHeight) {
    return `
      min-height: ${height};
    `
  }

  return css`
    min-height: ${height};

    ${up('lg')} {
      height: ${height};
    }
  `
}

export const Content = styled.div`
  background-color: ${colors('white')};
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: ${getPadding}px;

  ${getHeightStyle}

  ${up('lg')} {
    max-width: ${theme.spacing.comfortable * 43}px;
  }
`

export const StyledMenu = styled(Menu)`
  background-color: ${colors('white')};
  margin: ${spacing('comfortable')}px 0;
  padding: 0 ${spacing('comfortable')}px;
`
