import styled from 'styled-components'

export const CenterContainer = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: ${props => props.theme.spacing.cozy * 127.5}px;
  padding-left: 1rem;
  padding-right: 1rem;
  width: 100%;
`
