import * as React from 'react'
import clsx from 'clsx'
import { useRouter } from 'next/router'
import styled from 'styled-components'

import type { RouterLinkProps } from '~/components/router-link'
import Anchor from '~/truck/anchor'
import Icon from '~/truck/icon'

export interface CollapsibleMenuItemProps {
  title: string
  iconType: string
  children: React.ReactElement<RouterLinkProps> | React.ReactElement<RouterLinkProps>[]
  className?: string
  style?: React.CSSProperties
}

export interface CollapsibleAnchorProps {
  children: string
  selected?: boolean
}

const StyledContainer = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
`

const StyledContent = styled.div<{ $isOpen: boolean }>`
  display: ${props => (props.$isOpen ? 'flex' : 'none')};
  flex-direction: column;
  padding-left: ${props => props.theme.spacing.cozy * 4}px;
`

const StyledAnchor = styled(Anchor)`
  align-items: center;
  display: flex;
  height: ${props => props.theme.dimensions.buttonHeight}px;
`

export const CollapsibleMenuAnchor = React.forwardRef<HTMLAnchorElement, CollapsibleAnchorProps>((props, ref) => {
  const { selected, ...rest } = props

  return <StyledAnchor ref={ref} $color={selected ? 'primary' : 'black'} {...rest} />
})

export default function CollapsibleMenuItem(props: CollapsibleMenuItemProps) {
  const { title, iconType, children, className, style } = props

  const router = useRouter()

  const selected = React.useMemo(() => {
    const arr = React.Children.toArray(children) as React.ReactElement<RouterLinkProps>[]

    return arr.some(child => child.props.href === router.asPath)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.asPath])

  const [isOpen, setIsOpen] = React.useState(selected)

  return (
    <StyledContainer className={className} style={style}>
      <div
        className={clsx('flex h-12 items-center gap-2', selected && 'font-semibold text-brand-primary')}
        onClick={() => setIsOpen(v => !v)}
      >
        <Icon type={iconType} color="currentColor" />
        <span>{title}</span>
        <Icon className="ml-auto" type={isOpen ? 'caret-up' : 'caret-down'} color="currentColor" size="sm" />
      </div>
      <StyledContent $isOpen={isOpen}>{children}</StyledContent>
    </StyledContainer>
  )
}
